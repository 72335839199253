// Generated by Framer (2740f55)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
import * as sharedStyle from "../css/xZndidUCt";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["ZDf5YFgqG"];

const serializationHash = "framer-1qKKu"

const variantClassNames = {ZDf5YFgqG: "framer-v-1p2l3wf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({amount, height, id, width, ...props}) => { return {...props, dYYK93kud: amount ?? props.dYYK93kud ?? "1 request at a time"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;amount?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dYYK93kud, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ZDf5YFgqG", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1p2l3wf", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ZDf5YFgqG"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-ea29o9-container"} layoutDependency={layoutDependency} layoutId={"Kde7Ea4fu-container"}><Phosphor color={"var(--token-2d0196d5-4c11-460d-91d6-2fb81e415efd, rgb(255, 68, 0))"} height={"100%"} iconSearch={"House"} iconSelection={"Plus"} id={"Kde7Ea4fu"} layoutId={"Kde7Ea4fu"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-51fbaa80-2371-42f7-947f-8d22e2db73a1, rgb(41, 41, 41)))"}}>1 request at a time</motion.p></React.Fragment>} className={"framer-1p1jy6l"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"ebQFrG325"} style={{"--extracted-r6o4lv": "var(--token-51fbaa80-2371-42f7-947f-8d22e2db73a1, rgb(41, 41, 41))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={dYYK93kud} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1qKKu.framer-qgdxrc, .framer-1qKKu .framer-qgdxrc { display: block; }", ".framer-1qKKu.framer-1p2l3wf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 492px; }", ".framer-1qKKu .framer-ea29o9-container { flex: none; height: 24px; position: relative; width: 24px; }", ".framer-1qKKu .framer-1p1jy6l { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1qKKu.framer-1p2l3wf { gap: 0px; } .framer-1qKKu.framer-1p2l3wf > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-1qKKu.framer-1p2l3wf > :first-child { margin-left: 0px; } .framer-1qKKu.framer-1p2l3wf > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 491
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"dYYK93kud":"amount"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNiQcpG985: React.ComponentType<Props> = withCSS(Component, css, "framer-1qKKu") as typeof Component;
export default FramerNiQcpG985;

FramerNiQcpG985.displayName = "pricing line";

FramerNiQcpG985.defaultProps = {height: 28, width: 491};

addPropertyControls(FramerNiQcpG985, {dYYK93kud: {defaultValue: "1 request at a time", displayTextArea: false, title: "Amount", type: ControlType.String}} as any)

addFonts(FramerNiQcpG985, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...PhosphorFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})